import { createApp } from 'vue';
import { createPinia } from 'pinia';

import getToken from '../utils/get-token';
import { setCookie } from '../utils/cookie';
import apolloClientInit from '../utils/apollo-client';
import { useConfigStore } from '../pinia/modules/config';
import { useUserStore } from '../pinia/modules/user';
import { useCartStore } from '../pinia/modules/cart';

const initHobbiiStarter = async () => {
  const alreadyInitialized = !!window.hobbiiStore;

  if (!alreadyInitialized) {
    window.hobbiiStore = createPinia();

    const graphqlHost = import.meta.env.VITE_HOBBII_GATEWAY;

    window.hobbiiStore.use(() => ({
      apolloClient: apolloClientInit({ graphqlHost })
    })); // Apollo Client plugin

    // Set active Pinia instance.
    // Pinia needs access to an app to be available everywhere.
    createApp().use(window.hobbiiStore);

    const configStore = useConfigStore();
    const userStore = useUserStore();
    if (window.Hobbii.hobbiiStoreData) {
      const { countryCode, language, isLogged, pointsMultiplier } =
        window.Hobbii.hobbiiStoreData;

      configStore.$patch({
        countryCode,
        language,
        graphqlHost,
        pointsMultiplier
      });

      if (isLogged) {
        if (!sessionStorage.getItem('customer_data')) {
          const token = await getToken();
          if (!token) {
            await getToken(true);
          }
        }
      } else {
        sessionStorage.removeItem('customer_data');
        sessionStorage.removeItem('c_id');
        setCookie('c_id', '', -5);
        userStore.customer = null;
      }
    }

    if (sessionStorage.getItem('customer_data')) {
      userStore.customer = JSON.parse(sessionStorage.getItem('customer_data'));
    }

    configStore.getStarterData();

    const cartStore = useCartStore();
    cartStore.getCart();
  }
};

export { initHobbiiStarter };
