import axios from 'axios';
import { parseJwt } from './jwt';

export default async (isRetry = false) => {
  try {
    const authTokenApi = '/apps/cognito/api/auth/token';
    const sessionResponse = await axios.get(authTokenApi);

    let token;
    if (sessionResponse.data.token) {
      token = sessionResponse.data.token;
      setCustomerData(token);
    }
    return token;
  } catch (error) {
    let token;
    if (import.meta.env.DEV && !isRetry) {
      token = getDevToken();
    }

    return token;
  }
};

const getDevToken = () => {
  if (import.meta.env.VITE_CID) {
    const token = import.meta.env.VITE_CID;
    setCustomerData(token);
    return token;
  }
};

const setCustomerData = (token) => {
  const customerData = parseJwt(token);
  const customer = {
    uuid: customerData['cognito:username'],
    first_name: customerData.name,
    last_name: customerData.family_name,
    email: customerData.email
  };
  sessionStorage.setItem('customer_data', JSON.stringify(customer));
  sessionStorage.setItem('c_id', token);
};
