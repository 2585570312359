import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { ApolloLink } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import { InMemoryCache } from '@apollo/client/cache';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import getToken from './get-token';
import { useUserStore } from '../pinia/modules/user';

const recoveryLink = (onSuccess, onError) =>
  new RetryLink({
    delay: {
      initial: 0
    },
    attempts: {
      max: 2,
      retryIf: async (error) => {
        if (error.statusCode === 401) {
          const token = await getToken(true);
          if (token) {
            onSuccess();
          } else {
            onError();
          }
          return !!token;
        }
        return false;
      }
    }
  });

const enrichLink = (onSuccess) =>
  setContext(async (_, { headers }) => {
    let token;
    const sessionToken = sessionStorage.getItem('c_id');
    const customerData = sessionStorage.getItem('customer_data');

    if (sessionToken && customerData) {
      token = sessionToken;
    } else {
      token = await getToken();
      onSuccess();
    }

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json'
      }
    };
  });

const httpLink = (graphqlHost) =>
  createHttpLink({
    uri: `${graphqlHost}`
  });

const cache = new InMemoryCache({
  typePolicies: {
    CommunityQueries: {
      keyFields: ['__typename']
    }
  }
});

const apolloClientInit = (options) => {
  const userStore = useUserStore();

  const setReceivedCustomer = () => {
    const customer = JSON.parse(sessionStorage.getItem('customer_data'));
    userStore.customer = customer;
  };

  setReceivedCustomer();

  const cleanCustomer = () => {
    sessionStorage.removeItem('customer_data');
    sessionStorage.removeItem('c_id');
    userStore.customer = null;
  };

  return new ApolloClient({
    link: ApolloLink.from([
      recoveryLink(setReceivedCustomer, cleanCustomer),
      enrichLink(setReceivedCustomer),
      httpLink(options.graphqlHost)]),
    cache
  });
};

export default apolloClientInit;
